@import "@packfleet/ui/dist/index.css";

/*
  "@tailwind base" contains a rule that hides any element with the hidden attribute,
  regardless of the value of the attribute. Like this:

  [hidden] {
    display: none;
  }

  This rule overrides that rule for elements with the hidden="until-found".
*/
[hidden="until-found"] {
  display: initial;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 15px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Helvetica Neue, Arial, sans-serif;
}

html,
body,
body > div:first-child,
div#__next {
  /* This ensures that the innermost div element generated by next.js is set to full height. */
  height: 100%;
}

input:focus,
textarea:focus,
select:focus {
  outline: #194128 auto 5px;
}

button[disabled] {
  cursor: default;
}

.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23444444%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}

.firebase-emulator-warning {
  display: none;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animation-slideDown {
  animation-name: slideDown;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.swingIn-top {
  --transform-origin: top;
}

@keyframes highlightFlash {
  0% {
    background-color: initial;
  }
  25% {
    background-color: rgb(191 229 245 / 0.3);
  }
  50% {
    background-color: initial;
  }
  75% {
    background-color: rgb(191 229 245 / 0.3);
  }
  100% {
    background-color: initial;
  }
}

.highlight-flash {
  animation: highlightFlash 1s ease-in-out;
}
